import * as React from 'react';
import {
    Label,
    PrimaryButton,
    DefaultButton,
    IStackTokens,
    TextField,
    MessageBar,
    MessageBarType,
    Stack
} from '@fluentui/react';
import * as Portal from "Components/Framework/Page/CommonPage";
import { DeviceMapper, DeviceApiModel, DeviceUIModel, EmptyDeviceApiModel } from 'Models/DeviceModel';
import { Authorization, AuthorizedRole } from "Auth/Authorization";
import { DeviceInventoryService } from 'Services/DeviceInventoryService';
import { ResourceStrings } from 'Resources/ResourceKeys';
import { ScenarioResponseModel } from '../../../Models/ScenarioResponseModel';
import { ScenarioService } from 'Services/ScenarioService';
import { TracingService } from 'Services/TracingService';
import { UserContext } from 'Auth/UserContext';
import * as Common from "Components/Common/ValyrianCommonComponents";
import Loading from "Images/Loading.gif";
import ResourceManager from 'Resources/ResourceManager';
import "scss/site.css";

const Component_Name = 'Device Details';
const EditingFormTitle = ResourceManager.GetString(ResourceStrings.DeviceAction.EditLocation.Title);

const facility = Common.ResourceManager.GetString(
    Common.ResourceStrings.UpdateDeviceKeys.facility
);

const slot = Common.ResourceManager.GetString(
    Common.ResourceStrings.UpdateDeviceKeys.slot
);

const locker = Common.ResourceManager.GetString(
    Common.ResourceStrings.UpdateDeviceKeys.locker
);

const room = Common.ResourceManager.GetString(
    Common.ResourceStrings.UpdateDeviceKeys.room
);

const facilityInvalidMessage = Common.ResourceManager.GetString(
    Common.ResourceStrings.UpdateDeviceKeys.facilityInvalidMessage
);

const slotInvalidMessage = Common.ResourceManager.GetString(
    Common.ResourceStrings.UpdateDeviceKeys.slotInvalidMessage
);

const lockerInvalidMessage = Common.ResourceManager.GetString(
    Common.ResourceStrings.UpdateDeviceKeys.lockerInvalidMessage
);

const roomInvalidMessage = Common.ResourceManager.GetString(
    Common.ResourceStrings.UpdateDeviceKeys.roomInvalidMessage
);

let tracingService = TracingService.getInstance();
let deviceInventoryService = DeviceInventoryService.getInstance();
const stackTokens: IStackTokens = { childrenGap: 15 };

interface IDeviceEditFormProps {
    device: DeviceApiModel;
    onClose: any;
}

export class EditDeviceLocationForm extends React.Component<IDeviceEditFormProps, any, any> implements Portal.ICommonPage {
    private device: DeviceUIModel;
    private allScenarios: ScenarioResponseModel[];
    static contextType = UserContext;
    private Constant = Common.Constants.getInstance();

    // Required member for a page
    public pageProps: Portal.CommonPageProps = {
        authRequired: true,
        pageTitle: "Edit Device",
        authorizedRoles: [AuthorizedRole.OrganizationAdministrator,
                          AuthorizedRole.ScenarioChamp]
    };

    constructor(props: any) {
        super(props);
        this.allScenarios = ScenarioService.getInstance().GetScenarioData();
        this.device = DeviceMapper.ApiToUI(props.device, ScenarioService.MapScenarioName(props.device.scenarioIds, this.allScenarios));
        this.state = {
            serialNumber: this.device.deviceSerialNumber,
            facility: this.device.facility,
            slot: this.device.slot,
            locker: this.device.locker,
            room: this.device.room,
            etag: this.device.etag,
            isLoading: false,
            formErrors: {
                facility: "",
                slot: "",
                locker: "",
                room: ""
            },
            isDeviceUpdated: false,
            showMessageBar: false,
            errorMessage: "",
            errorCode: "",
            selectedDevice: props.device,
            deviceUpdateApiCall: false,
            apiResponse: "",
            deviceApiCall: "",
            apiSuccess: false
        };
        this.validateField = this.validateField.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.deviceUpdateSuccessMessage = this.deviceUpdateSuccessMessage.bind(this);
        this.handleSubmitDeviceUpdate = this.handleSubmitDeviceUpdate.bind(this);
        this.DeviceFriendlyErrorMessageMapping = this.DeviceFriendlyErrorMessageMapping.bind(this);
        this.handleCancel = this.handleCancel.bind(this);

        console.log("Editing form");
    };

    DeviceFriendlyErrorMessageMapping = () => {
        switch (this.state.errorCode) {
            case "InternalServerError":
                return Common.ResourceManager.GetString(
                    Common.Helper.stringFormat(
                        Common.ResourceStrings.UpdateDeviceKeys
                            .UpdateDeviceFailedInternalServerErrorMessage,
                        this.Constant.getSupportEmail
                    )
                );
            case "PreconditionFailed":
                return Common.ResourceManager.GetString(
                    Common.Helper.stringFormat(
                        Common.ResourceStrings.UpdateDeviceKeys
                            .UpdateDeviceFailedPreconditionErrorMessage,
                        this.state.serialNumber
                    )
                );
            case "NotFound":
                return Common.ResourceManager.GetString(
                    Common.Helper.stringFormat(
                        Common.ResourceStrings.UpdateDeviceKeys
                            .UpdateDeviceFailedNotFoundErrorMessage,
                        this.Constant.getSupportEmail
                    )
                );
            case "BadRequest":
                return Common.ResourceManager.GetString(
                    Common.Helper.stringFormat(
                        Common.ResourceStrings.UpdateDeviceKeys
                            .UpdateDeviceFailedBadRequestGenericMessage,
                        this.state.errorMessage
                    )
                );
            case "Forbidden":
                return Common.ResourceManager.GetString(
                    Common.Helper.stringFormat(
                        Common.ResourceStrings.UpdateDeviceKeys
                            .UpdateDeviceFailedUnauthorizedErrorMessage,
                        this.state.errorMessage
                    )
                );
            default:
                return Common.ResourceManager.GetString(
                    Common.Helper.stringFormat(
                        Common.ResourceStrings.UpdateDeviceKeys
                            .UpdateDeviceFailedGenericMessage,
                        this.state.errorMessage
                    )
                );
        }
    };

    deviceUpdateSuccessMessage = () => {
        return Common.ResourceManager.GetString(
            Common.Helper.stringFormat(
                Common.ResourceStrings.UpdateDeviceKeys
                    .UpdateDeviceSuccessMessage,
                this.state.serialNumber
            )
        );
    };

    validateField(fieldName: any, value: any ) {
        let fieldValidationErrors = this.state.formErrors;

        switch (fieldName) {
            case facility:
                let facilityValid = value?.length > 0;
                fieldValidationErrors.facility = facilityValid
                    ? ""
                    : facilityInvalidMessage;
                this.setState({
                    isLoading: false
                });
                break;
            case slot:
                let slotValid = value?.length > 0;
                fieldValidationErrors.slot = slotValid
                    ? ""
                    : slotInvalidMessage;
                this.setState({
                    isLoading: false
                });
                break;
            case locker:
                let lockerValid = value?.length > 0;
                fieldValidationErrors.locker = lockerValid
                    ? ""
                    : lockerInvalidMessage;
                this.setState({
                    isLoading: false
                });
                break;
            case room:
                let roomValid = value?.length > 0;
                fieldValidationErrors.room = roomValid
                    ? ""
                    : roomInvalidMessage;
                this.setState({
                    isLoading: false
                });
                break;
            default:
                break;
        }
        this.setState({ formErrors: fieldValidationErrors });
    };

    handleSubmitDeviceUpdate = async () => {
        const user = this.context;
        if (this.Authorize(user)) {
            this.setState({
                isLoading: true,
                deviceApiCall: false,
                deviceUpdateApiCall: false,
                isDeviceUpdated: false
            });

            this.validateField(facility, this.state.facility);
            this.validateField(slot, this.state.slot);
            this.validateField(locker, this.state.locker);
            this.validateField(room, this.state.room);
            if (
                this.state.formErrors.facility === "" &&
                this.state.formErrors.slot === "" &&
                this.state.formErrors.locker === "" &&
                this.state.formErrors.room === "") {
                await this.UpdateDeviceAsync().then((results) => {
                    this.setState({ isLoading: false });
                });
            }
        } else {
            this.setState({ errorCode: "Forbidden" });
        }
    };

    handleInputChange = (
        event: React.FormEvent<
            HTMLInputElement | HTMLTextAreaElement | HTMLDivElement
        >,
        newValue: string | undefined
    ): void => {
        const target = event.target as HTMLInputElement;
        const newAsset = { [target.id]: newValue };
        this.setState(newAsset);
    };

    private Authorize(user: any): boolean {
        return user.IdToken && Authorization.AuthorizeUser(user.roles, this.pageProps.authorizedRoles);
    };

    private UpdateDeviceAsync = async () => {
        const user = this.context;
        this.setState({
            isLoading: true
        });
        await deviceInventoryService.UpdateDeviceAsync(user.IdToken, this.state.facility, this.state.slot, this.state.locker, this.state.room, this.state.etag, this.props.device.id).then(results => {
            if (results.error) {
                this.setState({
                    deviceUpdateApiCall: true,
                    showMessageBar: true,
                    isDeviceUpdated: false,
                    errorMessage: results.error.message,
                    errorCode: results.error.code
                });
            } else if (results.id === this.device.id) {
                this.setState({
                    isLoading: false,
                    serialNumber: this.device.deviceSerialNumber,
                    deviceUpdateApiCall: true,
                    isDeviceUpdated: true,
                    showMessageBar: true,
                    errorMessage: "",
                    id: results.id,
                    errorCode: ""
                });
            }
        });
    };

    private handleCancel = () => {
        this.setState({
            selectedDevice: EmptyDeviceApiModel,
            selectedItemShouldOpen: false
        });
    };

    closeMessageBar = () => {
        this.props.onClose();
        this.setState({
            deviceUpdateApiCall: false,
            isDeviceUpdated: false,
            showMessageBar: false,
            errorMessage: "",
            errorCode: "",
            deviceApiCall: false
        });

    };

    componentDidMount() {
        tracingService.trace(Component_Name, 'Displaying device editing form');
    }

    render() {
        return (
        <Portal.CommonPage {...this.pageProps}>
            <div className="wrapper">
                <div className="securedevicesform">
                    {
                        this.state.isLoading ? (
                            <div className="loading-overlay">
                                <img src={Loading} className="loading-icon" alt="Loading"></img>
                            </div>
                        ) : null
                    }
                    <div style={{ display: "flex" }}>
                        <button style={{ marginLeft: "auto" }} onClick={this.props.onClose}>
                            &#10005;
                        </button>
                    </div>
                    <Label className="securedevicesform-title">{ EditingFormTitle }</Label>
                        <div className="securedevicesform-body">

                            <div className="form__text">
                                <Label
                                    className="securedevices-label"
                                    htmlFor="serialnumber"
                                >
                                    Serial Number
                                </Label>
                                <TextField
                                    className="securedevices-input"
                                    id="serialnumber"
                                    value={this.device.deviceSerialNumber}
                                    disabled={true}
                                />
                            </div>

                            <div className="form__text">
                                <Label
                                    className="securedevices-label"
                                    htmlFor="devicescenario"
                                >
                                    Device Scenario
                                </Label>
                                <TextField
                                    className="securedevices-input"
                                    id="devicescenario"
                                    value={this.device.deviceType}
                                    disabled={true}
                                />
                            </div>

                            <div className="form__text">
                                <Label
                                    className="securedevices-label"
                                    htmlFor="devicestatus"
                                >
                                    Device Status
                                </Label>
                                <TextField
                                    className="securedevices-input"
                                    id="devicestatus"
                                    value={this.device.deviceStatus}
                                    disabled={true}
                                />
                            </div>

                            <div className="form__text">
                                <Label
                                    className="securedevices-label"
                                    required
                                    htmlFor="facility"
                                >
                                    Facility
                                </Label>
                                <TextField
                                    className="securedevices-input"
                                    id="facility"
                                    defaultValue={this.device.facility}
                                    onChange={(event, value) =>
                                        this.handleInputChange(event, value)
                                    }
                                />
                                <Label className="securedevices-error" style={{ color: "red" }}>
                                    {this.state.formErrors.facility}
                                </Label>
                            </div>

                            <div className="form__text">
                                <Label
                                    className="securedevices-label"
                                    htmlFor="slot"
                                >
                                    Slot
                                </Label>
                                <TextField
                                    className="securedevices-input"
                                    id="slot"
                                    defaultValue={this.device.slot}
                                    onChange={(event, value) =>
                                        this.handleInputChange(event, value)
                                    }
                                />
                                <Label className="securedevices-error" style={{ color: "red" }}>
                                    {this.state.formErrors.slot}
                                </Label>
                            </div>

                            <div className="form__text">
                                <Label
                                    className="securedevices-label"
                                    htmlFor="locker"
                                >
                                    Locker
                                </Label>
                                <TextField
                                    className="securedevices-input"
                                    id="locker"
                                    defaultValue={this.device.locker}
                                    onChange={(event, value) =>
                                        this.handleInputChange(event, value)
                                    }
                                />
                                <Label className="securedevices-error" style={{ color: "red" }}>
                                    {this.state.formErrors.locker}
                                </Label>
                            </div>

                            <div className="form__text">
                                <Label
                                    className="securedevices-label"
                                    required
                                    htmlFor="room"
                                >
                                    Room
                                </Label>
                                <TextField
                                    className="securedevices-input"
                                    id="room"
                                    defaultValue={this.device.room}
                                    onChange={(event, value) =>
                                        this.handleInputChange(event, value)
                                    }
                                />
                                <Label className="securedevices-error" style={{ color: "red" }}>
                                    {this.state.formErrors.room}
                                </Label>
                            </div>
                            <div className="securedevicesform-footer">
                                <Stack horizontal tokens={stackTokens}>
                                    <PrimaryButton
                                        onClick={this.handleSubmitDeviceUpdate}
                                        text="Save"
                                    />
                                    <DefaultButton
                                        onClick={this.props.onClose}
                                        text="Cancel"
                                    />
                                </Stack>
                            </div>
                        </div>
                        {this.state.showMessageBar && this.state.deviceUpdateApiCall ? (
                            <div className="securedevicesform-header">
                                {this.state.isDeviceUpdated ? (
                                    <div className="securedevicesform-header">
                                        {this.state.id ? (
                                            <MessageBar
                                                messageBarType={MessageBarType.success}
                                                isMultiline={true}
                                                onDismiss={() => this.closeMessageBar()}
                                            >
                                                {this.deviceUpdateSuccessMessage()}
                                            </MessageBar>
                                        ) : (
                                            <MessageBar
                                                messageBarType={MessageBarType.warning}
                                                isMultiline={true}
                                                onDismiss={() => this.closeMessageBar()}
                                            >
                                                {this.DeviceFriendlyErrorMessageMapping()}
                                            </MessageBar>
                                        )}
                                    </div>
                                ) : (
                                    <MessageBar
                                        messageBarType={MessageBarType.error}
                                        isMultiline={true}
                                        onDismiss={() => this.closeMessageBar()}
                                    >
                                        {this.DeviceFriendlyErrorMessageMapping()}
                                    </MessageBar>
                                )}
                            </div>
                        ) : (
                            <div></div>
                        )}
                    </div>
                </div>
        </Portal.CommonPage>
        );
    }
}