import * as React from 'react';
import { ActionButton, CommandBar, ICommandBarItemProps, IIconProps } from '@fluentui/react';
import { Constants } from "Utilities/Constants";
import { DeviceApiModel, DeviceUIModel, DeviceMapper, EmptyDeviceUIModel } from 'Models/DeviceModel';
import { DeviceInventoryService } from "Services/DeviceInventoryService";
import { ResourceStrings } from 'Resources/ResourceKeys';
import { ScenarioService } from 'Services/ScenarioService';
import { TracingService } from 'Services/TracingService';
import { UserContext } from 'Auth/UserContext';
import ResourceManager from 'Resources/ResourceManager';
import "scss/site.css";
import { ReassignDeviceForm } from './ReassignDeviceForm';
import { DecommissionDeviceForm } from './DecommissionDeviceForm';
import { EditDeviceLocationForm } from './EditDeviceLocationForm';
import { Authorization, AuthorizedRole } from '../../../Auth/Authorization';

const Component_Name = 'Device Details';
const DeviceStatus_New = "New";
const DeviceStatus_Ready = "Ready";
const DetailName_SerialNumber = ResourceManager.GetString(ResourceStrings.Device.SerialNumber);
const DetailName_DeviceAsset = ResourceManager.GetString(ResourceStrings.Device.AssetId);
const DetailName_Type = ResourceManager.GetString(ResourceStrings.Device.Scenario);
const DetailName_DeviceState = ResourceManager.GetString(ResourceStrings.Device.DeviceState);
const DetailName_Campus = ResourceManager.GetString(ResourceStrings.Locations.Campus);
const DetailName_Metro = ResourceManager.GetString(ResourceStrings.Locations.Metro);
const DetailName_Facility = ResourceManager.GetString(ResourceStrings.Locations.Facility);
const DetailName_Room = ResourceManager.GetString(ResourceStrings.Locations.Room);
const DetailName_Locker = ResourceManager.GetString(ResourceStrings.Locations.Locker);
const DetailName_Slot = ResourceManager.GetString(ResourceStrings.Locations.Slot);
const DetailName_RegistrationDate = ResourceManager.GetString(ResourceStrings.Device.AutoPilotRegistrationDate);
const DetailNotAvailable = ResourceManager.GetString(ResourceStrings.Common.NotAvailable);
const Command_Reassign = ResourceManager.GetString(ResourceStrings.DeviceAction.ReassignScenario.ActionName);
const Command_Decommission = ResourceManager.GetString(ResourceStrings.DeviceAction.DecommissionDevice.ActionName);
const Command_Edit = ResourceManager.GetString(ResourceStrings.DeviceAction.EditLocation.ActionName);

let tracingService = TracingService.getInstance();
let deviceInventoryService = DeviceInventoryService.getInstance();
const BackIcon: IIconProps = { iconName: 'ChromeBack' };

interface IDeviceDetailsProps {
    device: DeviceApiModel;
    onClose: any;
}

interface IDeviceDetailsState {
    device: DeviceUIModel;
    reassigning: boolean;
    actionsDisabled: boolean;
    deviceStatus: string;
    actionButtons: ICommandBarItemProps[];
    decommission: boolean;
    editing: boolean;
}

export class DeviceDetails extends React.Component<IDeviceDetailsProps, IDeviceDetailsState> {
    private device: DeviceUIModel;
    private deviceActionsDisabled: boolean;
    constructor(props: IDeviceDetailsProps) {
        super(props);
        this.device = DeviceMapper.ApiToUI(props.device, ScenarioService.MapScenarioName(props.device.scenarioIds, ScenarioService.getInstance().GetScenarioData()));
        this.deviceActionsDisabled = true;
        this.state = {
            device: this.device,
            reassigning: false,
            actionsDisabled: false,
            deviceStatus: props.device.deviceStatus,
            actionButtons: this.CreateCommandBarItems(),
            decommission: false,
            editing: false
        };
    }

    private PopulateDeviceDetails() {
        this.deviceActionsDisabled = this.device.deviceStatus !== DeviceStatus_New && this.device.deviceStatus !== DeviceStatus_Ready;
        this.setState({
            device: this.device,
            actionsDisabled: this.deviceActionsDisabled,
            deviceStatus: this.device.deviceStatus,
            actionButtons: this.CreateCommandBarItems(),
            editing: false,
            reassigning: false,
            decommission: false
        });
    };

    static contextType = UserContext;

    private RefreshDeviceAsync = async() => {
        const user = this.context;
        if (user.IdToken) {
            const filter = {
                key: Constants.getInstance().DeviceLocationSerialNumber,
                value: this.device.deviceSerialNumber
            };

            await deviceInventoryService.GetDeviceInventoryData(user.IdToken, [filter], "", "10")
                .then(results => {
                    if (results.devices?.length === 1) {
                        let apiDevice = results.devices[0];
                        this.device = DeviceMapper.ApiToUI(apiDevice, ScenarioService.MapScenarioName(apiDevice.scenarioIds, ScenarioService.getInstance().GetScenarioData()));
                        this.PopulateDeviceDetails();
                    }
                })
                .catch(error => {
                    tracingService.trace(Component_Name, error);
                    console.log(error);
                });
        }
    };

    private loggedInUserWithOrgAdminRole = (user: {
        roles: string[];
        IdToken: string;
    }) => {
        return user.IdToken && Authorization.AuthorizeUser(user.roles, [AuthorizedRole.OrganizationAdministrator, AuthorizedRole.CapacityManager]);
    };

    private loggedInUserWithScenarioChampRole = (user: {
        roles: string[];
        IdToken: string;
    }) => {
        return user.IdToken && Authorization.AuthorizeUser(user.roles, [AuthorizedRole.ScenarioChamp]);
    };

    private OpenReassignmentForm = () => {
        this.setState({ reassigning: true });
    };

    private OpenDecommissionForm = () => {
        this.setState({ decommission: true });
    };

    private OpenEditingForm = () => {
        this.setState({ editing: true });
    };

    private CloseForm = () => {
        this.RefreshDeviceAsync();
    };

    private CreateCommandBarItems(): ICommandBarItemProps[] {
        return [
            {
                key: 'reassign',
                text: Command_Reassign,
                iconProps: { iconName: 'Switch' },
                onClick: () => (this.OpenReassignmentForm()),
                disabled: this.deviceActionsDisabled
            },
            {
                key: 'decommission',
                text: Command_Decommission,
                iconProps: { iconName: 'Devices2' },
                onClick: () => (this.OpenDecommissionForm()),
                disabled: this.deviceActionsDisabled
            },
            {
                key: 'edit',
                text: Command_Edit,
                iconProps: { iconName: 'Edit' },
                onClick: () => (this.OpenEditingForm())
            }
        ];
    }

    componentDidMount() {
        tracingService.trace(Component_Name, 'Displaying device details');
        console.log("Details component got serial " + this.props.device.serialNumber);
        this.PopulateDeviceDetails();
    }

    private renderDetails() {
        return (
            <div style={{ width: '90%', margin: '1rem auto', position: 'relative' }}>

                <h1 className="title" style={{ marginBottom: '0.1rem' }}>
                    {this.props.device.serialNumber}
                </h1>
                <h5 className="header" style={{ marginBottom: '0.25rem' }}>Properties</h5>

                <div style={{ width: '100%', margin: '1rem auto', position: 'relative', padding: 0, borderBottom: '1px solid #ccc' }}
                    hidden={!this.loggedInUserWithOrgAdminRole(this.context) && !this.loggedInUserWithScenarioChampRole(this.context)}>
                    <CommandBar items={this.state.actionButtons} />
                </div>

                <div id="device-details" style={{ width: '97%', margin: '2rem auto' }} >
                    <h4>{ResourceManager.GetString(ResourceStrings.Common.Overview)}</h4>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        width: '100%'
                    }}>
                        <div style={{ flex: "1 0 50%" }}>
                            <h5 className="header">{DetailName_SerialNumber}</h5>
                            <p>{this.device.deviceSerialNumber}</p>

                            <h5 className="header">{DetailName_DeviceAsset}</h5>
                            <p>{this.state.device.deviceAssetId || DetailNotAvailable}</p>

                            <h5 className="header">{DetailName_RegistrationDate}</h5>
                            <p>{this.device.apRegistrationDateUTCString || DetailNotAvailable}</p>
                        </div>
                        <div style={{ flex: "1 0 50%" }}>
                            <h5 className="header">{DetailName_DeviceState}</h5>
                            <p>{this.state.device.deviceStatus || DetailNotAvailable}</p>

                            <h5 className="header">{DetailName_Type}</h5>
                            <p>{this.state.device.deviceType || DetailNotAvailable}</p>

                            <h5 style={{ display: this.state.device.hasVariant ? 'block' : 'none' }} className="header">{this.state.device.variantLabelName || DetailNotAvailable}</h5>
                            <p style={{ display: this.state.device.hasVariant ? 'block' : 'none' }}>{this.state.device.variantOptionName || DetailNotAvailable}</p>
                        </div>
                    </div>

                    <h4>{ResourceManager.GetString(ResourceStrings.Locations.Location)}</h4>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        width: '100%',
                        marginTop: '1%'
                    }}>
                        <div style={{ flex: "1 0 50%" }}>
                            <h5 className="header">{DetailName_Facility}</h5>
                            <p>{this.state.device.facility || DetailNotAvailable}</p>

                            <h5 className="header">{DetailName_Campus}</h5>
                            <p>{this.state.device.campus || DetailNotAvailable}</p>

                            <h5 className="header">{DetailName_Metro}</h5>
                            <p>{this.state.device.metro || DetailNotAvailable}</p>
                        </div>
                        <div style={{ flex: "1 0 50%" }}>
                            <h5 className="header">{DetailName_Room}</h5>
                            <p>{this.state.device.room || DetailNotAvailable}</p>

                            <h5 className="header">{DetailName_Locker}</h5>
                            <p>{this.state.device.locker || DetailNotAvailable}</p>

                            <h5 className="header">{DetailName_Slot}</h5>
                            <p>{this.state.device.slot || DetailNotAvailable}</p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    public render(): JSX.Element { 
        return (
            <div style={{ backgroundColor: '#fefefe', minHeight: '500px', position: 'relative', width: '100%', margin: 'auto', paddingBottom: '4rem' }}>
                <div style={{ textAlign: 'left', paddingTop: '1rem', marginLeft: '2rem' }}>
                    <ActionButton onClick={this.props.onClose} iconProps={BackIcon} disabled={this.state.reassigning || this.state.decommission}>{ResourceManager.GetString(ResourceStrings.Device.Inventory)}</ActionButton>
                </div>
                {this.state.reassigning ? <ReassignDeviceForm device={this.props.device} onClose={this.CloseForm} /> : this.state.decommission ? <DecommissionDeviceForm device={this.props.device} onClose={this.CloseForm} />
                    : this.state.editing ? <EditDeviceLocationForm device={this.props.device} onClose={this.CloseForm} /> : this.renderDetails()}

            </div>
        );
    }
}